<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              v-if="canEditSet"
              variant="primary"
              :disabled="isAddRecordActive"
              @click="openSetModel(false)"
            >
              <span>{{ $t('BUTTON.ADD_SET') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
            <b-button
              v-if="canEditSet"
              variant="primary"
              :disabled="isAddRecordActive"
              class="ml-1"
              @click="openSetAllModel()"
            >
              <span>{{ $t('BUTTON.CHANGE_TIME_ALL') }} </span>
              <feather-icon icon="ClockIcon" />
            </b-button>
            <b-button
              v-if="canEditSet"
              variant="primary"
              :disabled="isAddRecordActive"
              class="ml-1"
              @click="deleteSetAll()"
            >
              <span>{{ $t('BUTTON.DELETE_ALL') }} </span>
              <feather-icon icon="MinusCircleIcon" />
            </b-button>
            <b-avatar
              v-if="allSetReports"
              rounded
              variant="light-success"
              size="34"
              class="ml-1"
            >
              <feather-icon
                icon="BarChart2Icon"
                class="text-success  cursor-pointer"
                size="30"
                @click="downloadSetReport()"
              />
            </b-avatar>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mt-1 mb-md-0"
          >
            <small
              v-if="content.id"
              class="text-muted"
            >
              <b-badge
                pill
                variant="light-primary"
                class="mr-1 text-capitalize small"
              >
                {{ content.contentType.name }}
              </b-badge>
              <b-badge
                pill
                variant="light-warning"
                class="mr-1 text-capitalize small"
              >
                {{ content.contentFrequency.name }}
              </b-badge>
              <b-badge
                pill
                variant="light-info"
                class="mr-1 text-capitalize small"
              >
                {{ content.contentStatus.name }}
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
                class="mr-1 text-capitalize small"
              >
                {{ formatDateTime(content.start) }}
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
                class="mr-1 text-capitalize small"
              >
                {{ formatDateTime(content.end) }}
              </b-badge>
            </small>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card :title="$t('SETS_MILESTONES')">
      <full-calendar
        class="full-calendar"
        :options="calendarOptions"
      />
    </b-card>
    <set-model
      :content="content"
      :get-events="getEvents"
      :edit-set="editSet"
    />
    <set-all-model
      :content="content"
      :get-events="getEvents"
    />
  </div>
</template>
<script>

import {
  VBToggle,
  VBTooltip,
  BCard,
  BButton,
  BRow,
  BCol,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction' // For selectable interactions
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'
import { updateBreadcrumb } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import constants from '@/constants/static.json'
import SetModel from './SetModel.vue'
import SetAllModel from './SetAllModel.vue'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    SetModel,
    SetAllModel,
    FullCalendar,
    BBadge,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  mixins: [mixinDate, mixinList],
  data() {
    return {
      contents: [],
      canEditSet: true,
      content: useJwt.getContent() || {},
      userData: useJwt.getUser() || {},
      editSet: {
        id: 0,
        contentId: 0,
        start: null,
        end: null,
      },
      breadCrumb: constants.BREADCRUMB,
      calendarOptions: {
        timeZone: 'local',
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear',

        },
        events: [],
        eventClick: this.handleEventClick,
      },
    }
  },
  computed: {
    ...mapState('settime', ['allSettimes']),
    ...mapState('report', ['allSetReports']),
  },
  async created() {
    try {
      const routeId = parseInt(router.currentRoute.params.id, 10)
      if (routeId) {
        const response = await this.fetchContentByID(routeId)
        if (response && response.data) {
          this.content = response.data
          this.configureBreadcrumbs()
        }
      }
    } catch (error) {
      this.errorMessage(this.$i18n.t('MESSAGE.PAGE_LOAD_FAILED'))
    }
  },
  setup() {
    const isAddRecordActive = ref(true)
    const {
      successMessage, errorMessage,
    } = mixinAlert()

    return {
      isAddRecordActive,
      successMessage,
      errorMessage,
      updateBreadcrumb,
    }
  },
  methods: {
    ...mapActions('settime', [
      'fetchAllSettimes',
      'deleteSettimeAll',
      'fetchSetReport']),
    ...mapActions('content', ['fetchContentByID']),
    ...mapActions('report', ['fetchReportByID']),
    getEvents() {
      const list = this.allSettimes.map((item, index) => {
        const eventColor = this.getEventColor(item.start)
        const startDate = item.start.split('T')[0]
        const startTime = item.start.split('T')[1].substring(0, 5)
        const endDate = item.end ? item.end.split('T')[0] : startDate
        const endTime = item.end ? item.end.split('T')[1].substring(0, 5) : '-'
        return {
          id: item.id.toString(),
          title: `Set - ${index + 1} - ${startDate} ${startTime} to ${endDate} ${endTime}`,
          start: item.start.replace('Z', ''),
          end: item.end.replace('Z', ''),
          allDay: true,
          backgroundColor: eventColor,
          borderColor: eventColor,
        }
      })
      this.calendarOptions = {
        ...this.calendarOptions,
        events: list,
      }
    },
    configureBreadcrumbs() {
      const company = useJwt.getBreadcrumb(this.breadCrumb.COMPANY)
      const project = useJwt.getBreadcrumb(this.breadCrumb.PROJECT)
      const content = useJwt.getBreadcrumb(this.breadCrumb.CONTENT)
      const breadcrumbs = [
        { text: company.name, to: `/companies/company-view/${company.id}`, active: false },
        { text: project.name, to: `/project/project-view/${project.id}`, active: false },
        { text: content.name, to: `/content/content-view/${content.id}`, active: false },
        { text: this.$i18n.t('SETS_MILESTONES'), active: true },
      ]
      updateBreadcrumb(this, breadcrumbs)
    },
    deleteSetAll() {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')}   ${this.$i18n.t('SET_MILESTONE')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteSettimeAll(this.content.id).then(async () => {
              await this.fetchAllSettimes(this.content.id)
              this.getEvents()
              this.successMessage(this.$i18n.t('MESSAGE.SET_DELETED'))
            }).catch(error => {
              if (error.response && error.response.status === 500) {
                this.errorMessage(this.$i18n.t('MESSAGE.INTERNAL_SERVER_ERROR'))
              } else {
                this.errorMessage(this.$i18n.t('MESSAGE.OPERATION_FAILED'))
              }
            })
          }
        })
    },
    handleEventClick(clickInfo) {
      const eventId = clickInfo.event.id
      const foundObject = this.allSettimes.find(item => item.id.toString() === eventId)
      if (foundObject) {
        this.openSetModel(foundObject)
      } else {
        console.log('Object not found')
      }
    },
    async downloadSetReport() {
      await this.fetchSetReport(`${this.content.id}.xlsx`).then(response => {
        if (response) {
          this.successMessage(this.$i18n.t('MESSAGE.REPORT_DOWNLOAD'))
        }
      })
    },
    openSetModel(data) {
      if (data) {
        this.editSet = { ...data }
      }
      this.$bvModal.show('set-modal')
    },
    openSetAllModel() {
      this.$bvModal.show('set-time-modal')
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

</style>
